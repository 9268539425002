<template>
  <div>
      <LiefengContent>
        <template v-slot:title>{{'发布设置'}}</template>
        <template v-slot:toolsbarRight>
            <From class="search-left">
                <div @click="changeType(index)" :class="typeIndex == index ? 'item item-active' :'item'" v-for="(item,index) in typeList" :key="index">{{item.title}}</div>
            </From>
            <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input
                    v-model="searchData.title"
                    placeholder="请填写标题"
                    style="width:150px;text-align:left"
                    />
                </FormItem>
                <FormItem>
                    <Select transfer v-model="searchData.operType" style="width:150px;text-align:left" placeholder="请选择发布方式">
                        <Option value="" >全部</Option>
                        <Option value="1" >人工发布</Option>
                        <Option value="2" >系统发布</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <DatePicker
                         transfer
                        type="daterange"
                        @on-change="changeDate"
                        placement="bottom-end"
                        placeholder="请选择发布时间"
                        style="width: 200px"
                        v-model="searchTime"
                    ></DatePicker>
                </FormItem>
                <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
                <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button> 
             </Form>
        </template>
         <template v-slot:contentArea>
             <!--
                 talbeColumns:[], //需要展示的列的内容
                tableData:[], //需要展示的表格的内容
                loading:false, //表格是否显示加载效果，默认不显示false
                page:1,       //默认进来第几页
                total:0,      //默认进来显示总数
                pageSize:20,  //默认进来显示分页多少条数据
            -->
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
            ></LiefengTable>
      </template>
      </LiefengContent>
       <!--弹窗-->
       <!--
           title:弹窗标题
           value:是否显示弹窗，false为不弹，true弹出，默认false
           inputFn:触发关闭按钮回调函数
       -->
        <LiefengModal
        title="驳回信息"
        :value="value"
        @input="inputFn"
        :fullscreen="false"
        >
        <template v-slot:contentarea>
            <!--在这里编写弹窗内容-->
            <div class="form">
                <p style="text-align:center">该操作会将这条信息的发布状态变更为<span style="color:red">待提交/已驳回</span>状态,请填写驳回原因。</p>
                 <Form :label-width="100" style="margin:20px 0;">
                    <FormItem>
                        <span slot="label" class="validate">商品分类:</span>
                            <Input type="textarea" v-model="reason" placeholder="请输入驳回原因" maxlength="200" show-limit/>
                        </FormItem>
                </Form>
                
            </div>
        </template>
        <template v-slot:toolsbar>
            <!--弹窗关闭取消按钮-->
            <Button type="info" @click="inputFn(false)" style="margin-right:10px">取消</Button>
            <Button type="primary" @click="saveReason">确定</Button>
        </template>
        </LiefengModal>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
    data(){
        return{
            talbeColumns:[
                {
                    title: "所属栏目",
                    key: "columnName",
                    minWidth: 200,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{
                            style:{
                                minHeight:'48px',
                                lineHeight:'48px'
                            }
                        },[
                            h('span',{
                                style:{
                                    color:'#ffffff',
                                    background:'#2d8cf0',
                                    padding:'5px 10px',
                                    borderRadius:'5px',
                                    userSelect:'none',
                                    cursor:'pointer'
                                },
                                on:{
                                    click: () =>{ 
                                        if(params.row.url.indexOf('http') > -1 || params.row.url.indexOf('https') > -1){
                                            let link = document.createElement("a");
                                            if(params.row.appCode && params.row.appCode == 'suiyue'){
                                                link.href = `/#${params.row.url}?menuId=${params.row.menuCode}`
                                            }else{
                                                link.href = `${params.row.url}?menuId=${params.row.menuCode}`;
                                            }
                                            document.body.appendChild(link);
                                            link.click();
                                        }else{
                                            this.$router.push(`${params.row.url}?menuId=${params.row.menuCode}`)
                                        }
                                    }
                                }
                            }
                            // [
                            //     h('a',{
                            //         style:{
                            //             color:'#ffffff',
                            //         },
                            //         href:params.row.url,
                            //     },params.row.columnName)
                            // ]
                            ,params.row.columnName)
                        ])
                    }
                },
                {
                    title: "标题",
                    key: "title",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "发布范围",
                    key: "pubScope",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "发布时间",
                    key: "gmtCreate",
                    minWidth: 200,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd hh:mm:ss') : '')
                    }
                },
                {
                    title: "发布方式",
                    key: "operType",
                    minWidth: 200,
                    align: "center"
                },
                // {
                //     title: "发布次数",
                //     key: "operType",
                //     minWidth: 200,
                //     align: "center"
                // },
                {
                    title: "状态",
                    minWidth: 200,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.status == 1 ?'发布成功':params.row.status == 2?'发布失败':'待发布' )
                    }
                },
                {
                    title: "操作",
                    align: "center",
                    width: 180,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                        "div",
                        {
                            style: {
                                textAlign: "center"
                            }
                        },
                        [
                            h(
                            "Dropdown",
                            {
                                props: {
                                transfer: true
                                // trigger: "click",
                                }
                            },
                            [
                                h(
                                "Button",
                                {
                                    props: {
                                    type: "success",
                                    size: "small",
                                    icon: "ios-arrow-down"
                                    }
                                },
                                "编辑"
                                ),
                                h(
                                "DropdownMenu",
                                {
                                    slot: "list"
                                },
                                [ 
                                    h(
                                    "DropdownItem",
                                    {
                                            nativeOn: {
                                                click: () => {
                                                    this.$Modal.confirm({
                                                        title: "温馨提示",
                                                        content:
                                                            `<p>该操作会将这条信息的发布状态变更为<span style="color:red">待发布</span>状态,确定要继续撤回吗?</p>`,
                                                        onOk: () => {
                                                            this.detailMessage = params.row
                                                            this.isBussiness(this.detailMessage.businessType,this.detailMessage.functionType,3)
                                                        }
                                                    })
                                                }
                                            },
                                            style: {
                                                textAlign: "center",
                                                display:params.row.delStatus == '1'?'none':'block'
                                            }
                                    },
                                    "撤回"
                                    ),
                                    h(
                                    "DropdownItem",
                                    {
                                        nativeOn: {
                                            click: () => {
                                                this.value = true
                                                this.reason = ''
                                                this.detailMessage = params.row
                                            }
                                        },
                                        style: {
                                             textAlign: "center",
                                             display:params.row.delStatus == '1'?'none':'block'
                                        }
                                    },
                                    "驳回"
                                    ),
                                     h(
                                    "DropdownItem",
                                    {
                                        nativeOn: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                        title: "温馨提示",
                                                        content:
                                                            `<p>该操作会将这条信息的发布状态变更为<span style="color:red">已归档</span>状态,确定要继续归档吗?</p>`,
                                                        onOk: () => {
                                                            this.detailMessage = params.row
                                                            this.isBussiness(this.detailMessage.businessType,this.detailMessage.functionType,5)
                                                        }
                                                    })
                                                
                                            }
                                        },
                                        style: {
                                             textAlign: "center",
                                             display:params.row.delStatus == '1'?'none':'block'
                                        }
                                    },
                                    "归档"
                                    ),
                                    h(
                                    "DropdownItem",
                                    {
                                        nativeOn: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                        title: "温馨提示",
                                                        content:
                                                            `<p>确定要删除该信息吗？</p>`,
                                                        onOk: () => {
                                                            this.detailMessage = params.row
                                                            this.isDelete(this.detailMessage.businessType,this.detailMessage.functionType,params.row.delStatus,params.row.id)
                                                        }
                                                    })
                                                
                                            }
                                        },
                                        style: {
                                             textAlign: "center"
                                        }
                                    },
                                    "删除"
                                    )
                                ]
                                )
                            ]
                            )
                        ]
                        );
                    }
                    }
            ], //需要展示的列的内容
            tableData:[], //需要展示的表格的内容
            loading:false, //表格是否显示加载效果，默认不显示false
            page:1,       //默认进来第几页
            total:0,      //默认进来显示总数
            pageSize:20,  //默认进来显示分页多少条数据
            value:false,
            currentPage:0,

            // 查询参数
            searchData:{
                startTime:'',
                endTime:''
            },
            searchTime:[],
            todayTime:this.$core.formatDate(new Date(),'yyyy-MM-dd'),
            typeList:[
                {title:'今日发布'},
                {title:'全部发布'}
            ],
            typeIndex:0,

            reason:'',

            detailMessage:{}
        }
    },
    created(){
        // 生命周期，dom元素加载完之后触发，进页面需要显示table数据，需要获取表格接口
        this.getList()
    },
    methods:{
        // 方法部分
        // 分页改变的回调函数
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 分页多选框选择返回的数组
        tableSelect(val){

        },
        // 弹窗关闭事件
        inputFn(status){
            this.value = status
            this.reason = ''
        },
        // 查询按钮
        searchBtn(){
            this.getList()
        },
        // 重置按钮
        resetBtn(){
            this.searchData={}
            this.searchTime = []
            // this.todayTime = this.$core.formatDate(new Date(),'yyyy-MM-dd')
            // this.typeIndex = 0
            this.getList()
        },
        // 查询时间change事件
        changeDate(val){
            this.searchData.startTime = val[0]
            this.searchData.endTime = val[1]
        },
        // 今日发布，全部发布
        changeType(index){
            this.typeIndex = index
            if(index == 0)this.todayTime = this.$core.formatDate(new Date(),'yyyy-MM-dd')
            else this.todayTime = ''
            this.getList()
        },
        // 驳回原因确认按钮
        saveReason(){
            if((this.reason ?? '') == ''){
                this.$Message.warning({
                    content:'驳回原因不能为空',
                    background:true
                })
                return
            }
            this.isBussiness(this.detailMessage.businessType,this.detailMessage.functionType,2)
            
        },
        // 判断是什么类型。跑什么change接口
        isBussiness(businessType,functionType,status){
            // 驳回:2,归档：5，撤回：3
            if(businessType == 0){
                // 辅具租赁、信息类
                if(functionType == 27){
                    // 辅具租赁
                    this.shopChange(status)
                }else{
                    // 信息类
                    this.informatChange(status)
                }
            }else{
                // 问卷
                this.queChange(status)
            }
        },
        // 判断是什么类型。跑什么delete接口
        isDelete(businessType,functionType,status,id){
            if(status == 1){
                this.deleteInformation(id)
            }else{
                // 驳回:2,归档：5，撤回：3
                if(businessType == 0){
                // 辅具租赁、信息类
                if(functionType == 27){
                    // 辅具租赁
                    this.shopDelete()
                }else{
                    // 信息类
                    this.informatDelete()
                }
                }else{
                    // 问卷
                    this.queDelete()
                }
            }
            
            
            
        },
        deleteInformation(informationPublishId){
            this.$post('/old/api/pc/information/deleteInformationPublish',{
                informationPublishId,
            }).then(res => {
                if (res.code == 200) {
                this.$Message.success({
                    background: true,
                    content: "删除成功"
                })
                this.getList()
                }else {
                this.$Message.error({
                    background: true,
                    content: "删除失败"
                })
                return
                }
            })
        },
        // 接口部分
        // 获取分页接口
        getList(){
            /*
                该函数为异步代码，
                可以在res里边获取接口返回的参数

                注意：proxy是代理名称，需要传什么需要到proxy找对应域名，
                orgCode、oemCode为上传的报文

                .then方法是返回接口参数的
            */ 
           this.loading = true //显示加载效果
            this.$get('/old/api/pc/information/queryAllByPage',{
                page:this.page,
                pageSize:this.pageSize,
                ...this.searchData,
                todayTime: this.todayTime ? this.todayTime : ''
                // ...
            }).then(res=>{
                this.loading = false // 拿到数据隐藏loading
                if(res.code == 200){
                    // 如果接口返回code == 200,正常给表格数组赋值
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                    this.$Message.error({
                        content:'获取列表数据失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 辅具租赁
        shopChange(status){
            this.$post("/datamsg/api/pc/information/goodsLease/change", {
                status,
                id: this.detailMessage.informationId,
                staffName: parent.vue.loginInfo.userinfo.realName,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.detailMessage.businessType,
                functionType: this.detailMessage.functionType,
                terminal: "2",
                reason:this.reason ? this.reason : '',
                releaseWay: ''
              }).then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "操作成功",
                  });
                  this.value = false
                  this.getList()
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                  return
                }
              });
        },
        // 信息类的
        informatChange(status){
            this.$post("/info/api/pc/information/v2/change", {
                status,
                id: this.detailMessage.informationId,
                staffName: parent.vue.loginInfo.userinfo.realName,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.detailMessage.businessType,
                functionType: this.detailMessage.functionType,
                terminal: "2",
                reason: this.reason ? this.reason : '',
                releaseWay:''
              }).then(res => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "操作成功"
                  });
                    this.value = false
                  this.getList()
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc
                  });
                  return
                }
              });
        },
        // 问卷类的change
        queChange(status){
            this.$post("/voteapi/api/pc/affiche/emp/changeAffiche", {
                infoId: this.detailMessage.informationId,
                status,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.detailMessage.businessType,
                functionType: this.detailMessage.functionType,
                reason: this.reason ? this.reason : '',
                releaseWay:''
            })
                .then((res) => {
                if (res.code == 200) {
                    this.$Message.success({
                        background: true,
                        content: "操作成功！",
                    });
                 
                } else {
                    this.$Message.error({
                        background: true,
                        content: "操作失败！",
                        });
                    }
                })
                .catch((err) => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败！",
                    });
                });
        },

        // 辅具租赁删除
        shopDelete(){
            this.$post( "/info/api/pc/information/v2/deleteById",
                      {
                      infoId: this.detailMessage.informationId,
                      operatorCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                      orgCode:parent.vue.loginInfo.userinfo.orgCode,
                      oemCode: parent.vue.oemInfo.oemCode,
                      businessType: this.detailMessage.businessType,
                      functionType: this.detailMessage.functionType,
                      terminal: "2"
                    }
                    ).then(res => {
                      if (res.code == 200) {
                        this.$Message.success({
                          background: true,
                          content: "删除成功"
                        });
                        this.getList()
                      } else {
                        this.$Message.success({
                          background: true,
                          content: res.desc
                        });
                      }
                    });
        },
        // 信息类删除
        informatDelete(){
             this.$post("/info/api/pc/information/v2/deleteById",
                  {
                    infoId: this.detailMessage.informationId,
                    operatorCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode:parent.vue.loginInfo.userinfo.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    businessType: this.detailMessage.businessType,
                    functionType: this.detailMessage.functionType,
                    terminal: "2"
                  }
                ).then(res => {
                  if (res.code == 200) {
                    this.$Message.success({
                      background: true,
                      content: "删除成功"
                    });
                    this.getList()
                  } else {
                    this.$Message.success({
                      background: true,
                      content: res.desc
                    });
                    return
                  }
                });
        },
        // 问卷类删除
        queDelete(){
            this.$get("/voteapi/api/pc/affiche/deleteAfficheInfo", {
                infoId: this.detailMessage.informationId,
            }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功"
              })
              this.getList()
            }else {
              this.$Message.error({
                background: true,
                content: "删除失败"
              })
              return
            }
          })
        }
    },
    components:{
        LiefengContent,
        LiefengTable,
        LiefengModal
    }
}
</script>

<style lang="less" scoped>
// /deep/#toolsbarRight {
//   width: 1000%;
//   display: flex;
//   justify-content: space-between;
  .search-left{
      display: flex;
      .item{
          width: 80px;
          height: 35px;
          line-height: 35px;
          border: 1px solid #e6e6e6;
          text-align: center;
          user-select:none;
          cursor:pointer;
          &:nth-child(1){
              border-right: none;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
          }
          &:nth-child(2){
              border-left: none;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
          }
          &-active{
              background:#2d8cf0 ;
              color: #ffffff;
          }
      }
  }
//   .search {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
    
//   }
// }
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.ivu-form-item-content{
    margin-left: 100px;
}
</style>